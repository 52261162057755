@import '~crazy-ui-next/src/scss/common/colors';
@import '~crazy-ui-next/src/scss/common/shadows';
@import '~crazy-ui-next/src/scss/common/typography';

  .notification {
    width: 380px;

    border: 1px solid $yellow-500;
    border-radius: 10px;

    background-color: white;
    box-shadow: $shadow-s;

    .header {
      display: flex;
      padding: 15px 0 9px 12px;
      height: 56px;
      width: 100%;
      gap: 12px;
      border-bottom: 1px solid $yellow-500;
      border-radius: 10px 10px 0 0;
      align-items: center;
      background-color: $yellow-100;

      h4 {
        @include text-body2-medium;
        color: $text-color;
        text-transform: uppercase;
        line-height: 17px;
        word-break: break-word;
      }

      svg {
        width: 42px;
        height: 34px;
        fill: $yellow-500;
      }
    }

    ul {
      padding: 0 16px;
      color: $text-color;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $gray-500;
      }

      li {
        padding: 12px 0px;
        font-size: 14px;

        div {
          display: flex;
          margin-bottom: 4px;

          span {
            @include text-body2;
            width: 50%;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid $yellow-500;
        }
      }
    }
  }
